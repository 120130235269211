<template>
  <v-app>
    <v-app-bar app color="grey darken-2" dark>
      <img
        src="https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"
        alt="Logo HEIG-VD"
        height="70%"
        class="logo"
      />
      <div class="d-flex align-center ml-5">ACCÈS EC+G</div>
      <v-spacer></v-spacer>
      <user-profile />
    </v-app-bar>

    <v-main>
      <admin-part />
    </v-main>
  </v-app>
</template>

<script>
import AdminPart from "@/components/AdminPart";
import UserProfile from "@/components/UserProfile";
export default {
  name: "App",
  components: {
    AdminPart,
    UserProfile,
  },
};
</script>