var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"my-10",attrs:{"outlined":"","type":"info","prominent":"","border":"left"}},[_vm._v(" Téléchargez le "),_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.downloadReport($event)}}},[_vm._v("rapport")]),_vm._v(" complet des demandes d'accès. ")])],1)],1),_c('v-divider'),_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.bookings,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"locale":"fr-CH","footer-props":{
          itemsPerPageOptions: [10, 30, 50],
          itemsPerPageText: 'Élements par page',
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
        var items = ref.items;
return [_c('tbody',{staticClass:"text-left"},_vm._l((items),function(item){return _c('tr',{key:item._id},[_c('td',[_vm._v(_vm._s(_vm.format(_vm.parseISO(item.date), "dd.MM.yyyy")))]),_c('td',[_vm._v(_vm._s(item.student.lastname))]),_c('td',[_vm._v(_vm._s(item.student.firstname))]),_c('td',[_c('a',{attrs:{"href":("mailto:" + (item.student.email))}},[_vm._v(_vm._s(item.student.email))])]),_c('td',[_vm._v(" "+_vm._s(_vm.format(_vm.parseISO(item.createdAt), "dd.MM.yyyy HH:mm"))+" ")])])}),0)]}},{key:"footer.page-text",fn:function(ref){
        var pageStart = ref.pageStart;
        var pageStop = ref.pageStop;
        var itemsLength = ref.itemsLength;
return [_vm._v(_vm._s(pageStart)+"-"+_vm._s(pageStop)+" de "+_vm._s(itemsLength))]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }