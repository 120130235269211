<template>
  <div class="text-center">
    <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="300" offset-y>
      <template v-slot:activator="{ on }">
        <v-btn small v-on="on" fab text class="ma-0" :ripple="false">
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-card v-if="currentUser">
        <v-list>
          <v-list-item>
            <v-list-item-icon small>
              <v-avatar color="grey" size="48">
                <span class="white--text subtitle">{{shortName}}</span>
              </v-avatar>
            </v-list-item-icon>
            <v-list-item-content>{{currentUser.name}}</v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-item @click="this.$keycloak.logoutFn">
            <v-list-item-content>Se déconnecter</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  mounted() {
    this.currentUser = this.$keycloak.tokenParsed
  },
  computed: {
    shortName: function() {
      return `${this.$keycloak.tokenParsed.given_name.substr(
        0,
        1
      )}${this.$keycloak.tokenParsed.family_name.substr(0, 1)}`;
    }
  },
  data: () => ({
    menu: false,
    currentUser: undefined
  })
};
</script>